import * as React from 'react';

const Largest = () => (
  <svg
    width="256"
    height="37"
    viewBox="0 0 256 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_703_16112)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.3143 25.7577L0.457153 30.5961C3.04763 34.0115 7.61905 36.573 14.019 36.573C23.4667 36.573 28.9524 31.3077 28.9524 24.3346C28.9524 20.7769 26.6667 18.6423 24.2286 16.65L21.7905 14.5153C20.2667 13.2346 19.5048 12.2384 19.5048 10.9576C19.5048 9.24995 20.7238 7.68457 23.6191 7.68457C25.6 7.68457 27.581 8.3961 29.4095 10.2461L36.2667 5.40765C34.5905 2.98842 30.6286 0.42688 24.5333 0.42688C16.3048 0.42688 10.2095 5.54995 10.2095 12.2384C10.2095 15.5115 12.1905 17.7884 14.781 19.923L17.2191 22.0576C18.8952 23.4807 19.6572 24.3346 19.6572 25.6153C19.6572 27.323 18.2857 29.173 14.6286 29.173C11.7333 29.173 9.14287 27.8923 7.3143 25.7577ZM98.4381 25.7577L91.581 30.5961C94.0191 34.0115 98.7429 36.573 105.143 36.573C114.59 36.573 120.076 31.3077 120.076 24.3346C120.076 20.7769 117.79 18.6423 115.352 16.65L112.914 14.5153C111.39 13.2346 110.629 12.2384 110.629 10.9576C110.629 9.24995 111.848 7.68457 114.743 7.68457C116.724 7.68457 118.705 8.3961 120.533 10.2461L127.39 5.40765C125.714 2.98842 121.752 0.42688 115.657 0.42688C107.429 0.42688 101.333 5.54995 101.333 12.2384C101.333 15.5115 103.314 17.7884 105.905 19.923L108.343 22.0576C110.019 23.4807 110.781 24.3346 110.781 25.6153C110.781 27.323 109.41 29.173 105.752 29.173C102.857 29.173 100.267 27.8923 98.4381 25.7577ZM97.2191 8.39611H88.381L80.1524 35.7192H71.0095L79.2381 8.39611H69.7905L72.0762 0.996102H99.6571L97.2191 8.39611ZM63.6953 35.8615V1.13842H51.0476L29.1048 35.8615H38.8572L43.1238 28.6038H55.6191V35.8615H63.6953ZM55.6191 7.82688V21.2038H47.5429L55.6191 7.82688Z"
        fill="currentColor"
      />
    </g>
    <path
      d="M255.232 9.28979C255.232 13.7063 252.338 17.9705 246.45 19.2904C248.48 20.5595 249.8 22.4886 249.8 25.3822C249.8 30.6617 245.942 36.2458 237.972 36.2458H219.646L229.85 0.710571H245.028C250.105 0.710571 255.232 3.09651 255.232 9.28979ZM242.896 8.32526H236.804L235.028 14.417H241.576C244.47 14.417 246.145 12.9448 246.145 10.8635C246.145 8.88367 244.571 8.32526 242.896 8.32526ZM236.195 28.6311C239.546 28.6311 240.815 26.702 240.815 24.773C240.815 22.8439 239.596 21.8286 237.058 21.8286H232.946L230.966 28.6311H236.195Z"
      fill="#FA5333"
    />
    <path
      d="M202.709 36.9565C192.251 36.9565 188.596 30.7632 190.982 22.4378L197.226 0.710571H206.364L199.916 23.3008C198.901 26.9051 200.373 28.7326 203.216 28.7326C205.551 28.7326 207.379 27.7173 208.445 23.9607L215.146 0.710571H224.283L217.329 25.0776C215.197 32.4384 210.831 36.9565 202.709 36.9565Z"
      fill="#FA5333"
    />
    <path
      d="M161.147 36.2458L171.35 0.710571H180.488L172.569 28.225H186.681L184.346 36.2458H161.147Z"
      fill="#FA5333"
    />
    <path
      d="M142.864 36.9566C135.401 36.9566 132 32.997 132 27.0067C132 15.6862 140.173 0 154.692 0C161.951 0 165.454 3.90887 166.012 8.52845L156.875 11.5743C156.519 9.94986 155.555 8.32539 153.169 8.32539C147.026 8.32539 141.595 18.5798 141.595 25.1285C141.595 27.159 142.508 28.6312 144.539 28.6312C146.925 28.6312 149.412 26.6514 151.494 23.6055L159.311 27.7175C157.332 30.7633 151.849 36.9566 142.864 36.9566Z"
      fill="#FA5333"
    />
    <defs>
      <clipPath id="clip0_703_16112">
        <rect width="128" height="37" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Largest;
