import * as React from 'react';

const Small = () => (
  <svg
    width="115"
    height="17"
    viewBox="0 0 115 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_464_11582)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.3143 11.8346L0.207153 14.0577C1.38096 15.6269 3.45239 16.8039 6.35239 16.8039C10.6333 16.8039 13.1191 14.3846 13.1191 11.1808C13.1191 9.54617 12.0833 8.56539 10.9786 7.65001L9.87382 6.66924C9.18334 6.08078 8.83811 5.62309 8.83811 5.03462C8.83811 4.25001 9.39048 3.53078 10.7024 3.53078C11.6 3.53078 12.4976 3.8577 13.3262 4.7077L16.4333 2.48463C15.6738 1.37309 13.8786 0.196167 11.1167 0.196167C7.3881 0.196167 4.6262 2.55001 4.6262 5.62309C4.6262 7.12693 5.52382 8.17309 6.69763 9.15386L7.80239 10.1346C8.56192 10.7885 8.90715 11.1808 8.90715 11.7692C8.90715 12.5539 8.28572 13.4039 6.62858 13.4039C5.31667 13.4039 4.14287 12.8154 3.3143 11.8346ZM44.6048 11.8346L41.4976 14.0577C42.6024 15.6269 44.7429 16.8039 47.6429 16.8039C51.9238 16.8039 54.4095 14.3846 54.4095 11.1808C54.4095 9.54617 53.3738 8.56539 52.2691 7.65001L51.1643 6.66924C50.4738 6.08078 50.1286 5.62309 50.1286 5.03462C50.1286 4.25001 50.681 3.53078 51.9929 3.53078C52.8905 3.53078 53.7881 3.8577 54.6167 4.7077L57.7238 2.48463C56.9643 1.37309 55.169 0.196167 52.4071 0.196167C48.6786 0.196167 45.9167 2.55001 45.9167 5.62309C45.9167 7.12693 46.8143 8.17309 47.9881 9.15386L49.0929 10.1346C49.8524 10.7885 50.1976 11.1808 50.1976 11.7692C50.1976 12.5539 49.5762 13.4039 47.9191 13.4039C46.6071 13.4039 45.4333 12.8154 44.6048 11.8346ZM44.0524 3.8577H40.0476L36.3191 16.4116H32.1762L35.9048 3.8577H31.6238L32.6595 0.457701H45.1571L44.0524 3.8577ZM28.8619 16.4769V0.52309H23.131L13.1881 16.4769H17.6072L19.5405 13.1423H25.2024V16.4769H28.8619ZM25.2024 3.59616V9.74232H21.5429L25.2024 3.59616Z"
        fill="currentColor"
      />
    </g>
    <path
      d="M114.165 4.08328C114.165 6.02452 112.894 7.89881 110.305 8.47895C111.198 9.03678 111.778 9.88468 111.778 11.1565C111.778 13.4771 110.082 15.9315 106.579 15.9315H98.5239L103.009 0.312378H109.68C111.912 0.312378 114.165 1.36109 114.165 4.08328ZM108.743 3.65934H106.066L105.285 6.3369H108.163C109.435 6.3369 110.171 5.68982 110.171 4.77499C110.171 3.90478 109.48 3.65934 108.743 3.65934ZM105.798 12.5846C107.271 12.5846 107.828 11.7367 107.828 10.8888C107.828 10.0409 107.293 9.59461 106.177 9.59461H104.37L103.5 12.5846H105.798Z"
      fill="#FA5333"
    />
    <path
      d="M91.0793 16.2439C86.4828 16.2439 84.8762 13.5217 85.9249 9.86236L88.6694 0.312378H92.6858L89.852 10.2417C89.4058 11.8259 90.0529 12.6292 91.3024 12.6292C92.3288 12.6292 93.1321 12.1829 93.6006 10.5318L96.546 0.312378H100.562L97.5054 11.0226C96.5683 14.258 94.6493 16.2439 91.0793 16.2439Z"
      fill="#FA5333"
    />
    <path
      d="M72.811 15.9315L77.296 0.312378H81.3123L77.8315 12.4061H84.0345L83.0081 15.9315H72.811Z"
      fill="#FA5333"
    />
    <path
      d="M64.775 16.2439C61.495 16.2439 60 14.5035 60 11.8705C60 6.89473 63.5924 0 69.9739 0C73.1647 0 74.7043 1.71811 74.9497 3.74859L70.9334 5.08738C70.7772 4.37336 70.3533 3.65934 69.3045 3.65934C66.6047 3.65934 64.2172 8.16658 64.2172 11.045C64.2172 11.9375 64.6188 12.5846 65.5113 12.5846C66.56 12.5846 67.6534 11.7144 68.5682 10.3756L72.0044 12.1829C71.1342 13.5217 68.7244 16.2439 64.775 16.2439Z"
      fill="#FA5333"
    />
    <defs>
      <clipPath id="clip0_464_11582">
        <rect width="58" height="17" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Small;
