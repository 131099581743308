import * as React from 'react';

const Medium = () => (
  <svg
    width="173"
    height="25"
    viewBox="0 0 173 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_464_11583)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.91427 17.4038L0.307129 20.6731C2.04761 22.9808 5.11903 24.7115 9.41903 24.7115C15.7666 24.7115 19.4524 21.1538 19.4524 16.4423C19.4524 14.0385 17.9167 12.5961 16.2786 11.25L14.6405 9.80768C13.6167 8.94229 13.1047 8.26922 13.1047 7.40383C13.1047 6.24999 13.9238 5.1923 15.869 5.1923C17.2 5.1923 18.5309 5.67306 19.7595 6.92306L24.3667 3.65384C23.2405 2.01922 20.5786 0.288452 16.4833 0.288452C10.9547 0.288452 6.8595 3.74999 6.8595 8.26922C6.8595 10.4808 8.19046 12.0192 9.93093 13.4615L11.569 14.9038C12.6952 15.8654 13.2071 16.4423 13.2071 17.3077C13.2071 18.4615 12.2857 19.7115 9.82855 19.7115C7.88331 19.7115 6.14284 18.8461 4.91427 17.4038ZM66.1381 17.4038L61.5309 20.6731C63.169 22.9808 66.3428 24.7115 70.6428 24.7115C76.9905 24.7115 80.6762 21.1538 80.6762 16.4423C80.6762 14.0385 79.1405 12.5961 77.5024 11.25L75.8643 9.80768C74.8405 8.94229 74.3286 8.26922 74.3286 7.40383C74.3286 6.24999 75.1476 5.1923 77.0928 5.1923C78.4238 5.1923 79.7548 5.67306 80.9833 6.92306L85.5905 3.65384C84.4643 2.01922 81.8024 0.288452 77.7456 0.288452C72.1786 0.288452 68.0833 3.74999 68.0833 8.26922C68.0833 10.4808 69.4143 12.0192 71.1547 13.4615L72.7928 14.9038C73.919 15.8654 74.4309 16.4423 74.4309 17.3077C74.4309 18.4615 73.5095 19.7115 71.0524 19.7115C69.1071 19.7115 67.3667 18.8461 66.1381 17.4038ZM65.319 5.67307H59.3809L53.8524 24.1346H47.7095L53.2381 5.67307H46.8905L48.4262 0.673062H66.9571L65.319 5.67307ZM42.7952 24.2308V0.769221H34.2976L19.5547 24.2308H26.1071L28.9738 19.3269H37.369V24.2308H42.7952ZM37.369 5.28845V14.3269H31.9428L37.369 5.28845Z"
        fill="currentColor"
      />
    </g>
    <path
      d="M172.57 6.22455C172.57 9.1838 170.632 12.041 166.686 12.9254C168.046 13.7757 168.931 15.0683 168.931 17.0071C168.931 20.5446 166.346 24.2861 161.005 24.2861H148.726L155.563 0.476135H165.733C169.135 0.476135 172.57 2.07481 172.57 6.22455ZM164.305 5.57828H160.223L159.033 9.66H163.42C165.359 9.66 166.482 8.67358 166.482 7.279C166.482 5.95244 165.427 5.57828 164.305 5.57828ZM159.815 19.184C162.06 19.184 162.91 17.8915 162.91 16.5989C162.91 15.3064 162.094 14.6261 160.393 14.6261H157.638L156.312 19.184H159.815Z"
      fill="#FA5333"
    />
    <path
      d="M137.378 24.7623C130.371 24.7623 127.922 20.6126 129.52 15.0343L133.704 0.476135H139.827L135.507 15.6125C134.826 18.0275 135.813 19.252 137.718 19.252C139.282 19.252 140.507 18.5717 141.221 16.0547L145.711 0.476135H151.834L147.174 16.803C145.745 21.7351 142.82 24.7623 137.378 24.7623Z"
      fill="#FA5333"
    />
    <path
      d="M109.529 24.2861L116.366 0.476135H122.489L117.183 18.9119H126.639L125.074 24.2861H109.529Z"
      fill="#FA5333"
    />
    <path
      d="M97.2791 24.7624C92.279 24.7624 90 22.1093 90 18.0956C90 10.5104 95.4763 0 105.204 0C110.068 0 112.415 2.6191 112.79 5.7144L106.667 7.75526C106.429 6.6668 105.783 5.57835 104.184 5.57835C100.068 5.57835 96.4287 12.4492 96.4287 16.8371C96.4287 18.1977 97.041 19.1841 98.4015 19.1841C100 19.1841 101.667 17.8575 103.061 15.8167L108.3 18.5718C106.973 20.6127 103.3 24.7624 97.2791 24.7624Z"
      fill="#FA5333"
    />
    <defs>
      <clipPath id="clip0_464_11583">
        <rect width="86" height="25" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Medium;
