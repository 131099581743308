import React, { useState } from 'react';

import CroppedImage from 'sats-ui-lib/react/cropped-image';
import LinkButton from 'sats-ui-lib/react/link-button';
import Text from 'sats-ui-lib/react/text';
import VisuallyHidden from 'sats-ui-lib/react/visually-hidden';

import Spinner from '../../shared-ui/components/spinner/spinner';
import Logo from '../../shared-ui/components/logo/logo';

import { LogIn as Props } from './log-in.props';

const LogIn: React.FunctionComponent<Props> = ({
  description,
  disclaimer,
  image,
  link,
  redirectText,
  title,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <main className="log-in">
      <div className="log-in__content">
        <div className="log-in__texts-wrapper">
          <div className="log-in__logo log-in__logo--desktop">
            <Logo size={Logo.sizes.largest} color={Logo.colors.dark} />
          </div>
          <div className="log-in__logo log-in__logo--mobile">
            <Logo size={Logo.sizes.large} color={Logo.colors.dark} />
          </div>
          <VisuallyHidden>
            <Text elementName="h1">{title}</Text>
          </VisuallyHidden>
          <Text className="log-in__text" size={Text.sizes.small}>
            {description}
          </Text>
          {isLoading ? (
            <div className="log-in__loading">
              <div className="log-in__spinner">
                <Spinner
                  theme={Spinner.themes.inline}
                  size={Spinner.sizes.small}
                />
              </div>
              <Text theme={Text.themes.emphasis}>{redirectText}</Text>
            </div>
          ) : undefined}
          <LinkButton
            className="log-in__button"
            {...link}
            data-test-login
            variant={LinkButton.variants.cta}
            size={LinkButton.sizes.basic}
            onClick={() => {
              setIsLoading(true);
            }}
          />
          {disclaimer ? (
            <Text className="log-in__text" size={Text.sizes.interface}>
              {disclaimer}
            </Text>
          ) : undefined}
        </div>
        {image ? (
          <div className="log-in__image">
            <CroppedImage
              {...image}
              className="log-in__image--desktop"
              aspectRatio={CroppedImage.aspectRatios.threeFour}
            />
            <CroppedImage
              {...image}
              className="log-in__image--mobile"
              aspectRatio={CroppedImage.aspectRatios.threeTwo}
            />
          </div>
        ) : null}
      </div>
    </main>
  );
};

export default LogIn;
