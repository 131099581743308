import { ObjectValues } from 'sats-ui-lib/react/types';

export const colors = {
  dark: 'dark',
  light: 'light',
} as const;

export const sizes = {
  small: 'small',
  medium: 'medium',
  large: 'large',
  largest: 'largest',
} as const;

export const themes = {
  pride: 'pride',
} as const;

export type LogoProps = {
  color?: ObjectValues<typeof colors>;
  size?: ObjectValues<typeof sizes>;
  theme?: ObjectValues<typeof themes>;
};

export type LogoSvgProps = {
  size?: ObjectValues<typeof sizes>;
};
