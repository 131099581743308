import * as React from 'react';

const Large = () => (
  <svg
    width="182"
    height="27"
    viewBox="0 0 182 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_703_16124)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.25718 18.7961L0.328613 22.3269C2.19052 24.8192 5.47623 26.6884 10.0762 26.6884C16.8667 26.6884 20.8096 22.8461 20.8096 17.7577C20.8096 15.1615 19.1667 13.6038 17.4143 12.15L15.6619 10.5923C14.5667 9.65767 14.0191 8.93075 14.0191 7.99613C14.0191 6.74998 14.8953 5.60768 16.9762 5.60768C18.4 5.60768 19.8239 6.1269 21.1381 7.4769L26.0667 3.94614C24.8619 2.18075 22.0143 0.311523 17.6334 0.311523C11.7191 0.311523 7.33813 4.04998 7.33813 8.93075C7.33813 11.3192 8.76194 12.9808 10.6238 14.5384L12.3762 16.0961C13.581 17.1346 14.1286 17.7577 14.1286 18.6923C14.1286 19.9384 13.1429 21.2884 10.5143 21.2884C8.43337 21.2884 6.57147 20.3538 5.25718 18.7961ZM70.7524 18.7961L65.8239 22.3269C67.5762 24.8192 70.9715 26.6884 75.5715 26.6884C82.3619 26.6884 86.3048 22.8461 86.3048 17.7577C86.3048 15.1615 84.6619 13.6038 82.9096 12.15L81.1572 10.5923C80.062 9.65767 79.5143 8.93075 79.5143 7.99613C79.5143 6.74998 80.3905 5.60768 82.4715 5.60768C83.8953 5.60768 85.3191 6.1269 86.6334 7.4769L91.562 3.94614C90.3572 2.18075 87.5096 0.311523 83.1286 0.311523C77.2143 0.311523 72.8334 4.04998 72.8334 8.93075C72.8334 11.3192 74.2572 12.9808 76.1191 14.5384L77.8715 16.0961C79.0762 17.1346 79.6238 17.7577 79.6238 18.6923C79.6238 19.9384 78.6381 21.2884 76.0096 21.2884C73.9286 21.2884 72.0667 20.3538 70.7524 18.7961ZM69.8762 6.12691H63.5238L57.6096 26.0654H51.0381L56.9524 6.12691H50.1619L51.8048 0.726902H71.6286L69.8762 6.12691ZM45.781 26.1692V0.830754H36.6905L20.9191 26.1692H27.9286L30.9953 20.8731H39.9762V26.1692H45.781ZM39.9762 5.71152V15.4731H34.1715L39.9762 5.71152Z"
        fill="currentColor"
      />
    </g>
    <path
      d="M181.227 6.99618C181.227 10.1223 179.179 13.1406 175.011 14.0749C176.448 14.9732 177.382 16.3386 177.382 18.3868C177.382 22.1238 174.651 26.0763 169.01 26.0763H156.038L163.261 0.923584H174.004C177.598 0.923584 181.227 2.61241 181.227 6.99618ZM172.495 6.31346H168.183L166.926 10.6254H171.561C173.609 10.6254 174.795 9.58332 174.795 8.11009C174.795 6.70872 173.681 6.31346 172.495 6.31346ZM167.752 20.6865C170.124 20.6865 171.022 19.321 171.022 17.9556C171.022 16.5902 170.16 15.8715 168.363 15.8715H165.453L164.051 20.6865H167.752Z"
      fill="#FA5333"
    />
    <path
      d="M144.049 26.5794C136.647 26.5794 134.06 22.1956 135.749 16.3027L140.169 0.923584H146.637L142.073 16.9135C141.354 19.4648 142.397 20.7583 144.409 20.7583C146.062 20.7583 147.355 20.0397 148.11 17.3807L152.853 0.923584H159.321L154.398 18.1712C152.889 23.3814 149.799 26.5794 144.049 26.5794Z"
      fill="#FA5333"
    />
    <path
      d="M114.631 26.0763L121.853 0.923584H128.321L122.715 20.399H132.705L131.052 26.0763H114.631Z"
      fill="#FA5333"
    />
    <path
      d="M101.69 26.5794C96.4075 26.5794 94 23.7767 94 19.5366C94 11.5237 99.7851 0.420532 110.062 0.420532C115.2 0.420532 117.68 3.18734 118.075 6.45719L111.607 8.61314C111.355 7.4633 110.673 6.31346 108.984 6.31346C104.636 6.31346 100.791 13.5718 100.791 18.2071C100.791 19.6444 101.438 20.6865 102.875 20.6865C104.564 20.6865 106.325 19.2851 107.798 17.1291L113.332 20.0397C111.93 22.1956 108.05 26.5794 101.69 26.5794Z"
      fill="#FA5333"
    />
    <defs>
      <clipPath id="clip0_703_16124">
        <rect width="92" height="27" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Large;
