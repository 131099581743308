import * as React from 'react';
import classNames from 'classnames';

import Small from './logos/small';
import Medium from './logos/medium';
import Large from './logos/large';
import Largest from './logos/largest';
import PrideSmall from './logos/pride-small';
import PrideLarge from './logos/pride-large';

import { LogoProps, LogoSvgProps, sizes, colors, themes } from './logo.props';

const LogoSvg: React.FunctionComponent<LogoSvgProps> = ({ size }) => {
  switch (size) {
    case sizes.small: {
      return <Small />;
    }
    case sizes.medium: {
      return <Medium />;
    }
    case sizes.large: {
      return <Large />;
    }
    case sizes.largest:
    default: {
      return <Largest />;
    }
  }
};

const Logo: React.FunctionComponent<LogoProps> & {
  colors: typeof colors;
  sizes: typeof sizes;
  themes: typeof themes;
} = ({ size = sizes.large, color = colors.light, theme }) => {
  switch (theme) {
    case themes.pride: {
      switch (size) {
        case sizes.small: {
          return <PrideSmall />;
        }
        case sizes.large:
        default: {
          return <PrideLarge />;
        }
      }
    }
    default: {
      return (
        <div
          className={classNames('logo', {
            'logo--dark': color === colors.dark,
            'logo--light': color === colors.light,
          })}
        >
          <LogoSvg size={size} />
        </div>
      );
    }
  }
};

Logo.colors = colors;
Logo.sizes = sizes;
Logo.themes = themes;

export default Logo;
